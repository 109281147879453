.con{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.background{
    background-color: rgb(255 255 255 / 68%);;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.content{
    position: relative;
    width: calc(100% - 32px);
    max-width: 1024px;
    height: 75%;
    background: var(--light-brown);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 5px #804d19;

    .children{
        width: 100%;
        height: 100%;
    }
    .action{
        width: 100%;
    }
}