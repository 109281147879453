.list-container{
    display: flex;
    flex-flow: wrap;
}
.container{
    display: flex;
    background-color: var(--light-blue);
    border-radius: 50px;
    padding: 4px 12px;
    margin: 4px 2px;
    font-size: 14px;

    img{
        height: 16px;
        margin-right: 4px;
    }
}