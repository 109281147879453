.yoky-travel-container{
    display: flex;
    align-items: center;
    margin: 0 8px;
    .col1{
        margin-left: 16px;
        margin-right: 16px;
    }

    ul > li {
        margin-bottom: 8px;
        margin-right: 8px;
        a{
            color: var(--hover-blue-light);
        }
    }
}

.yoky-container{
    padding: "8px"; 
    line-height: 1.4;
    display: flex;    
    flex-direction: column;    
    .row1{
        width: 100%;
        line-height: 1.4;
    }
    .row2{
        width: 100%;
        padding: 0 8px 8px 8px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    ul > li {
        margin-bottom: 8px;
        margin-right: 8px;
        a{
            color: var(--hover-blue-light);
        }
    }
}

.gallery-container{
    width: 100%;
    height: 178px;
    margin-right: 4px;
}

/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

@media screen and ((orientation: portrait) and (max-width:600px)){
    .yoky-container{
        .row2{
            flex-direction: column;
        }
    }
    .gallery-container{
        height: 238px; //Height optimised for iPhone 12
        margin-right: auto;
        margin-bottom: 8px;
    }

}

@media screen and ((orientation: landscape) or (min-height:960px)){
}
@media screen and (max-width:768px){
}
