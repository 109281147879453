.container{
    display: flex;
    align-items: center;
    background-color:var(--location-light-brown);
    width: fit-content;
    border-radius: 16px;
    padding: 4px 8px;
    font-size: 12px;
    margin: 4px 2px;
    .icon{
        color: var(--dark-brown);
    }
    
    span{
        margin-left: 4px;
    }
}