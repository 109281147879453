.container{
    max-width: 128px;
    min-width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        height: 32px;
        // padding: 8px;
        padding: 4px 16px;
        box-sizing: border-box;
    }
}

/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

@media screen and ((orientation: portrait) or (max-height:600px)){
    .container{
        max-width: 256px;
        justify-content: flex-start;
        img{
            height: 50px;
            padding: 8px;
        }
    }
}
@media screen and ((orientation: landscape) or (min-height:960px)){
}
@media screen and (max-width:768px){
}
