.container {
    display: flex;
    width: 100%;
    border-radius: 16px;
}
.content {
    display: flex;
    flex-direction: row;
    border-radius: 16px;
    position: relative;
    padding: 16px 24px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.left {
    font-size: 16px;
    font-weight: 500;
    flex: 1;
    margin-left: 8px;
}

.right {
    font-size: 14px;
    font-weight: 300;
    flex: 1.2;
    margin-left: 8px;
}
