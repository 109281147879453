.container{
    display: flex;
    align-items: center;
    // padding: 12px 16px;
    font-size: 14px;

    span{
        margin-left: 4px;
    }    

    ul > li {
        margin-bottom: 8px;
        margin-right: 8px;
        a{
            color: var(--hover-blue-light);
        }
    }
}
