.header {
    height: 116px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.icon-container {
    width: 50%;
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

@media screen and (orientation:portrait){
    .header{
        margin-top: 16px;
        align-items: center;
    }
}