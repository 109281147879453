.container{
    width: 100%;
    height: 100%;
    border: 1px dashed var(--dark-brown);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.scroll-container{
    display: flex;align-items: center;
    width: 100%;
    min-height: 100px;
    flex: 1;

    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    scroll-behavior: smooth;
    background-color: #ffddb9;
    border-radius: 8px 8px 0 0;
}
.slide-container{
    display: flex;align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;    
    padding: 0 2px;
}
.action{
    position: relative;
    .expand-icon{
        position: absolute;
        right: 0px;
        top: 1px;
        color: var(--dark-brown);
        padding: 8px;
        font-size: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .expand-icon:hover{
        color: var(--hover-blue-light);
    }
}