.page-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 24px;

    .left,
    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 66vh;
        position: relative;
        max-width: 512px;

        .main-container {
            height: calc(66vh - 116px);
            display: flex;
            flex-direction: column;
        }
    }
    .main-image {
        height: 100%;
        img {
            height: 100%;
            border-radius: 16px;
        }
    }

    .left {
        padding: 0 6% 0 1%;

        .main-title {
            font-size: 40px;
            font-weight: 700;
            height: 116px;
            line-height: 1.2;
            .line1{                
                span{font-weight: 300;}
                color: var(--primary-text);
                font-size: 22px;
                font-weight: 500;                
            }
            .line2{
                font-size: 16px;
                font-weight: 300;
                margin-top: 4px;
                color: #4b4b4b;
                padding-left: 1px;
            }
        }
    }

    .right {
        padding: 0 1% 0 6%;

        .cta-card-list-container {
            width: 100%;
            flex: 1;
        }

        .cta-images {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            .cta-btn {
                width: calc(33% - 8px);
            }
        }
    }

    .cta-resume{
        // padding: 16px 0 90px 0;
        padding: 32px 0 64px;
        line-height: 1.4;
        font-size: 16px;
        color: gainsboro;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .landing-link{
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 4px;        
    }
}


/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

@media screen and ((orientation: portrait) or (max-height:600px)){
    .page-container {
        margin-top: 8px;
        flex-direction: column;

        .left,.right{
            padding: 0 16px;            
            width: 100%;
            box-sizing: border-box;
            max-width: 512px;
            margin: auto;            
        }
        .left{
            min-height: auto;
            .main-container{
                height: auto;
            }
            .main-image{
                img{
                    width: 100%;
                    height: auto;                    
                }
            }            
        }
        .right{
            min-height: 70vh;
            .main-container {
                height: calc(70vh - 116px);
                max-height: 512px;
                min-height: 400px;
            }
        }
    }
}

@media screen and ((orientation: landscape) or (min-height:960px)){
    .page-container {
        .left,.right{
            min-height:auto;
            .main-container{
                max-height: 512px;
            }
        }
    }
}
@media screen and (max-width:768px){
}

