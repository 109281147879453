.container{
    position: relative;
    margin: 0 16px 32px 16px;
    .rope-con{
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-around;
        z-index: 1;
        .left-rope,.right-rope{
            width: 0px;
            height: 50px;
            top: -17px;
            position: relative;
            border-left: 3px dotted gainsboro;
        }
    }
}
.top-tab-container{
    display: flex;
    justify-content: flex-start;
    margin-left: 8px;
    z-index: 2;
    position: relative;
}
.content{
    border: 1px solid gainsboro;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: flex-start;
    background-color: white;
    box-shadow: 0px 0px 5px #00000024;
    z-index: 2;
    position: relative;
    font-size: 14px;
    line-height: 1.4;
}
.col1{
    width: 75%;
    display: flex;
    align-items: center;
    border-right: 1px solid gainsboro;
    padding: 8px 0;
    margin: auto;
}
.col2{
    padding: 8px;
    width: 25%;
    display: flex;
    flex-flow: wrap;
}
.icon{
    margin-left: 16px;
}


/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

@media screen and ((orientation: portrait) and (max-width:600px)){
    .content{
        display: flex;
        flex-direction: column;
        .col1{
            width: 100%;
            flex-direction: column;
            align-items: center;
            padding: 16px 8px;
            box-sizing: border-box;
            border-right: none;
            border-bottom: 1px solid gainsboro;            
        }
        .col2{
            width: 100%;
        }   
    }
    .icon{
        margin-top: 8px;
        margin-left: 0px;
        margin-bottom: 8px;
    }
}
@media screen and ((orientation: landscape) or (min-height:960px)){
}
@media screen and (max-width:768px){
}