.container{
    position: relative;
    margin: 16px 16px 32px 16px;
}
.content{
    border: 1px solid gainsboro;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    background-color: white;
    box-shadow: 0px 0px 5px #00000024;
    z-index: 2;
    position: relative;
}
.col1{
    width: 75%;
}
.col2{
    border-left: 1px solid gainsboro;
    padding: 8px;
    width: 25%;
}

/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

@media screen and ((orientation: portrait) and (max-width:600px)){
    .content{
        flex-direction: column;
    }
    .col1{
        width: 100%;
    }
    .col2{
        width: 100%;
        box-sizing: border-box;
        border-left: none;
        border-top: 1px solid gainsboro;        
    }
}
@media screen and ((orientation: landscape) or (min-height:960px)){
}
@media screen and (max-width:768px){
}
