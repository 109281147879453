.container.bar{
    min-height: 3px;
    min-width: 50%;
    background-color: var(--light-brown);
    position: relative;
    border-radius: 3px;
    max-width: 500px;
    .progress{
        position: absolute;
        top:0;left: 0;
        height: 100%;
        border-radius: 3px;
        background-color: var(--dark-brown);
    }
}
.container.rotate{
    transform: rotate(0);
    animation: loading-rotation 3s infinite;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light-brown);
}
.loadingMsg{
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 2px;
}

@keyframes loading-rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}