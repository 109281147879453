.container{
    position: relative;
    margin: 16px;
}
.content{
    border: 1px solid gainsboro;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    background-color: white;
    box-shadow: 0px 0px 5px #00000024;
    z-index: 2;
    position: relative;
}
.col1{
    // width: 70%;
    flex: 1;
}
.col2{
    border-left: 1px solid gainsboro;
    padding: 8px;
    // width: 30%;
    width: 25%;
}

.col1.creative{
    width: auto;
    flex: 1;
}
.col2.creative{
    width: 25%;
}


/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

@media screen and ((orientation: portrait) and (max-width:700px)){
    .content{
        display: flex;
        flex-direction: column;        
    }
    .col2{
        width: 100%;
        box-sizing: border-box;
        border-left: none;
        border-top: 1px solid gainsboro;        
    }
    .col2.creative{
        width: 100%;
    }
}
@media screen and ((orientation: landscape) or (min-height:960px)){
}
@media screen and (max-width:768px){
}
