body {
  overflow-y: auto;
  overflow-x: hidden;


  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;

  --font-sans-fallback: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --font-mono: "Roboto Mono", Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;

  /* font-family: var(--font-sans,var(--font-sans-fallback)); */
  background-color: var(--bg-color);
  color: var(--primary-text);

  /*---Color pallette----*/
  --bg-color: #F4F4F4;
  --light-brown: #EBDED4;  
  --dark-brown: #C47F38;
  --light-blue: rgba(4, 116, 214, 0.07);

  --primary-text: #1B1A19;
  --hover-blue:#184f95;
  --hover-blue-light: #1469d6;

  --location-light-brown: #ffefdfa8;
  --tab-brown: #fff5ea;
  --tab-blue: #eff5fd;
  --tab-green: #effdf2;
  --tab-purple: #fbeffd;


  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px;
    background: transparent;
    height: 200%;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: var(--border-lightgray);
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
    
  ::-webkit-scrollbar:horizontal {
   height: 0;
   width: 0;
   display: none;
 }
    
 ::-webkit-scrollbar-thumb:horizontal {
   display: none;
 }
  
}
.overflow-hidden{
  overflow: hidden;
}
.modal-container{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.webgl-fluid-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;  
}
#root{
  z-index: 2;
  position: relative;
}
.webgl-container{
  position: absolute;
  top: -10vh;
  left: -10vw;
  width: 75vw;
  height: 100vh;
  z-index: 3;
  /* background-color: #ffa50099; */
  /* display: none; */
}
.dg.ac{
  z-index: 101;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

@media screen and ((orientation: portrait) and (max-width:600px)){
  .webgl-container{
    width: 100vw;
    height: 70vh;
    left: 0vw;
    top:40px;
    overflow:hidden;
  }
}
@media screen and ((orientation: portrait) and (min-width:600px)){
  .webgl-container{
    width: 100vw;
    height: 60vh;
    left: 0vw;
    top:0px;
    overflow:hidden;
  }
}
@media screen and ((orientation: landscape) and (max-height:600px)){
  .webgl-container{
    width: 100vw;
    height: 60vh;
    left: 0vw;
    top:50px;
    overflow:hidden;
  }
}
@media screen and (max-width:768px){
}

