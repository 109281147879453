.container{
    display: flex;
    align-items: center;
    // background-color:var(--location-light-brown);
    background-color: var(--light-blue);
    width: fit-content;
    border-radius: 16px;
    padding: 4px 8px;
    font-size: 12px;
    // margin: 4px 2px;
    margin: 2px 2px 0 4px;
    .icon{
        // color: var(--dark-brown);
        color: var(--hover-blue);
    }    
    span{
        margin-left: 6px;
    }
}