.creative-container{
    display: flex;    
    flex-direction: column;    
    .row1{
        width: 100%;
        line-height: 1.4;
    }
    .row2{
        width: 100%;
        padding: 0 8px 8px 8px;
        box-sizing: border-box;
    }
}
