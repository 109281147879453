.container{
    display: flex;
    border-bottom: 1px solid gainsboro;
}
.job{
    padding: 8px;
    border-left: 1px solid gainsboro;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
.company{
    display: flex;
    align-items: center;    
}
.time{
    padding: 8px;        
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid gainsboro;
}
.duration{
    text-align: right;
    display: flex;
    align-items: center;
}


.main-text{
    font-size: 15px;
    font-weight: 600;
}
.sub-text{
    font-size: 13px;
    font-weight: 300;    
    padding-top: 4px;     
}


/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

@media screen and ((orientation: portrait) or (max-height:600px)){
    .container{
        flex-direction: column;
    }
    .job{
        border-left: none;
        border-top: 1px solid gainsboro;
    }
    .time{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-left: none;
        border-top: 1px solid gainsboro;
    }
}
@media screen and ((orientation: landscape) or (min-height:960px)){
}
@media screen and (max-width:768px){
}


