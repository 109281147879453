.adi-travel-container{
    display: flex;
    align-items: center;
    .col1{
        margin-left: 16px;
        margin-right: 16px;
    }
    .col2{
        font-size: 24px;
        color: #ffcc82;
        border: 1px solid gainsboro;
        border-radius: 40px;
        min-width: 50px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.adi-container{
    padding: "8px"; 
    ul > li {
        margin-bottom: 8px;
        margin-right: 8px;
        a{
            color: var(--hover-blue-light);
        }
    }
}
