.header{
    display: flex;        
    .center{
        padding: 6px 16px 6px 16px;
        background-color: gainsboro;        
        font-size: 12px;
        display: flex;
        box-shadow: 0px 0px 3px #0000003d;
        .link{
            border-left: 1px solid #bbbbbb;
            padding-left: 8px;
            margin-left: 8px;
        }
    }
    .top{
        border-radius: 0 0 16px 16px;
    }
    .bottom{
        border-radius: 16px 16px 0 0;
    }
    .left,.right{
        width: 32px;
        height: 14px;
        background-color: gainsboro;
        .forefront{
            width: 100%;
            height: 100%;    
            background-color: var(--bg-color);
        }
        .bottom-left{
            border-radius: 0 0 16px 0;
        }
        .bottom-right{
            border-radius: 0 0 0 16px;
        }
        .top-left{
            border-radius: 0 16px 0 0;        
        }
        .top-right{
            border-radius: 16px 0 0 0;
        }        
    }
}
.top{
    align-items: flex-start;
}
.bottom{
    align-items: flex-end;
}
.header.blue,.header.brown,.header.green,.header.purple{
    .forefront{
        background: transparent;        
        border-left: 1px solid gainsboro;
        position: relative;
        top: 1px;                
    }
    .right,.left{
        background-color: transparent;        
    }    
}

.header.blue{
    .center{
        background-color: var(--tab-blue);
        box-shadow: 0px 0px 3px #0000003d;   
    }
    .forefront{
        box-shadow: -8px 0px 0 0 var(--tab-blue);
    }
}
.header.brown{
    .center{
        background-color: var(--tab-brown);
        box-shadow: 0px 0px 3px #0000003d;   
    }
    .forefront{
        box-shadow: -8px 0px 0 0 var(--tab-brown);
    }
}
.header.green{
    .center{
        background-color: var(--tab-green);
        box-shadow: 0px 0px 3px #0000003d;   
    }
    .forefront{
        box-shadow: -8px 0px 0 0 var(--tab-green);
    }
}
.header.purple{
    .center{
        background-color: var(--tab-purple);
        box-shadow: 0px 0px 3px #0000003d;   
    }
    .forefront{
        box-shadow: -8px 0px 0 0 var(--tab-purple);
    }
}


