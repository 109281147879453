.games-travel-container{
    display: flex;
    align-items: center;
    .col1{
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 16px;
    }
    ul > li {
        margin-bottom: 8px;
        margin-right: 8px;
        a{
            color: var(--hover-blue-light);
        }
    }
}

.games-container{
    display: flex;    
    flex-direction: column;    
    ul > li {
        margin-bottom: 8px;
        margin-right: 8px;
        a{
            color: var(--hover-blue-light);
        }
    }


    .row1{
        width: 100%;
        line-height: 1.4;
        display: flex;
        align-items: center;
        .award-container{
            width: 128px;
            box-sizing: border-box;
            padding: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .award-logo{width: 100%;}
            .google-logo{
                width: 80%;
                padding-top: 8px;
            }
        }
        .content{
            flex:1;
        }
    }
    .row2{
        width: 100%;
        padding: 0 8px 8px 8px;
        box-sizing: border-box;
    }
}


/*
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

@media screen and ((orientation: portrait) and (max-width:600px)){
    .games-container{
        .row1{
            display: flex;
            flex-direction: column;
        }   
    }
}
@media screen and ((orientation: landscape) or (min-height:960px)){
}
@media screen and (max-width:768px){
}