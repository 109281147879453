.container{
    position: relative;
    height: 100%;
    display: flex;
    padding: 4px 2px;
    box-sizing: border-box;
    border-radius: 4px;
    
    .progress{
        width: 50%;
        z-index: 2;
        position: absolute;
        top: calc(50% - 16px);
        left: 25%;
        display: flex;
    }
    .bg-img{
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px;
    }    
    .figure-con{
        height: 100%;
        position: relative;
        border-radius: 4px;
        .main-img{
            height: 100%;
            border-radius: 4px;
        }     
        .caption{
            position: absolute;
            bottom: 0;
            z-index: 2;
            min-height: 24px;
            background-color: var(--dark-brown);
            color: white;
            width: 100%;
            font-size: 12px;
            display: flex;
            align-items: center;
            padding: 2px 4px;
            box-sizing: border-box;
            border-radius: 0 0 4px 4px;
        }
    }
}
