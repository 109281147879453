.slated-container{
    padding: "8px"; 
    line-height: 1.4;
    display: flex;    
    flex-direction: column;    
    .row1{
        width: 100%;
        line-height: 1.4;
    }
    .row2{
        width: 100%;
        padding: 0 8px 8px 8px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    ul > li {
        margin-bottom: 8px;
        margin-right: 8px;
        a{
            color: var(--hover-blue-light);
        }
    }
}

.gallery-container-1{
    flex: 1.3;
    height: 178px;
    margin-right: 4px;
}
.gallery-container-2{
    flex: 1;
    height: 178px;
    margin-right: 4px;
}


