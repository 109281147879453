.navigation{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--tab-brown);
    border-radius: 0 0 8px 8px;
    padding: 8px 0;
    border-top: 1px dashed var(--dark-brown);
    .nav-icon{
        font-size: 24px;
        color: var(--dark-brown);
        cursor: pointer;
        margin: 0 4px;    
    }
    .nav-icon:hover{
        color: var(--hover-blue-light);
    }
    .nav-icon.inactive{
        opacity: 0.7;
        pointer-events: none;
    }
}